@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
  margin: 0;
  font-family: neusa-next-std-condensed, sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

@layer components {

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border: 0;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-moz-range-thumb {
    width: 50px;
    height: 50px;
    border: 0;
    cursor: pointer;
  }

  input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
    height: 100%;
    border-radius: 25px;

  }
  input[type="range"]::-moz-range-track {
    background-color: #9a905d;
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
  }
  input[type="range"]::-ms-fill-upper {
    background-color: #9a905d;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: #282bf6;
    border-radius: 25px;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 15px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 25px 0;
    border-radius: 25px;
    color: transparent;
  }
  .range-labels {
    margin: 18px -41px 0;
    padding: 0;
    list-style: none;
  }

  .range-labels li {
    position: relative;
    float: left;
    width: 90.25px;
    text-align: center;
    color: #b2b2b2;
    font-size: 14px;
    cursor: pointer;
  }
  .range-labels li::before {
    position: absolute;
    top: -25px;
    right: 0;
    left: 0;
    content: "";
    margin: 0 auto;
    width: 9px;
    height: 9px;
    background: #b2b2b2;
    border-radius: 50%;
  }
  .range-labels .active {
    color: #37adbf;
  }
  .range-labels .selected::before {
    background: #37adbf;
  }
  .range-labels .active.selected::before {
    display: none;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
